<template>
  <div class="d-flex wrap gap-code-flex">
    <div
      class="wrapper-div"
      v-for="(category, index) in crumbsList"
      :key="index"
    >
      <router-link 
      :to="onRouteChange(index)">
        <div class="d-flex g-10 align-center">
          <p class="title category-title">{{ categoryShow(category[titleKey]) }}</p>
          <img class="arrow" src="@/assets/icons/next-arrow50.png" />
        </div>
      </router-link>
    </div>

    <div class="d-flex">
      <div class="wrapper-div active">
        <div>
          <p class="active-title">{{ activeTitle }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    crumbsList: {
      type: Array,
      required: true,
    },
    activeTitle: {
      type: String,
      required: true,
    },
    titleKey: {
      type: String,
      default: "title",
    },
  },
  methods: {
    onRouteChange(clickedIndex){
      let self = this;
      let clickedObj = self.crumbsList[clickedIndex];
      let currentLanguage = self.getCurrentLanguageUrlBase();
      if(clickedObj.title === "Home"){
        // this.$router.push({name: clickedObj.title, params: {lang: currentLanguage }});
        return ({name: clickedObj.title, params: {lang: currentLanguage }});
      } else if (clickedObj.reference === "Shorts" || clickedObj.defaultTag === "Shorts"){
        // this.$router.push({
        //     name: "section-screen",
        //     params: { section: "movies", lang: currentLanguage },
        //   });
        return({
            name: "section-screen-bytes",
            params: { section: 'shortbytes', lang: currentLanguage },
          });
          
      }  else if(clickedObj.reference === "CONTENT"){
        // this.$router.push({
        //     name: "section-screen",
        //     params: { section: "movies", lang: currentLanguage },
        //   });
        // if(clickedObj.defaultTag.toUpperCase() === "TVSHOW" || clickedObj.defaultTag.toUpperCase() === "SERIES" || clickedObj.defaultTag.toUpperCase() === "NATAK" || clickedObj.defaultTag.toUpperCase() === "SHOWS") {
        //   return ({
        //     name: "section-screen",
        //     params: { section: "shows", lang: currentLanguage },
        //   });
        // }
        // return ({
        //     name: "section-screen",
        //     params: { section: "movies", lang: currentLanguage },
        //   });
        return ({name: "Home", params: {lang: currentLanguage }});
      }
      else if(clickedObj.reference === "SERIES" || clickedObj.reference.toUpperCase() === "NATAK" || clickedObj.reference.toUpperCase() === "SHOWS" || clickedObj.defaultTag === "Shows" || clickedObj.defaultTag === "Natak"){
        // this.$router.push({
        //     name: "section-screen",
        //     params: { section: "shows", lang: currentLanguage },
        //   });
        return ({
            name: "section-screen",
            params: { section: "shows", lang: currentLanguage },
          });
      }  else if(clickedObj.reference.toUpperCase() === "MUSIC" || clickedObj.defaultTag === "Music"){
          // this.$router.push({
          //   name: "section-screen-video",
          //   params: { section: 'music', lang: currentLanguage },
          // });
          // hindi-video-songs/category/songs
          return({ name: "screen-meta-redirection", params: { objecttype: 'hindi-video-songs', value: 'songs' , type: 'category', lang: currentLanguage } });

          // return ({
          //   name: "section-screen-video",
          //   params: { section: 'music', lang: currentLanguage },
          // });
      }  else if(clickedObj.reference.toUpperCase() === "KIDS" || clickedObj.defaultTag === "Kids"){
          // this.$router.push({
          //   name: "section-screen-video",
          //   params: { section: 'music', lang: currentLanguage },
          // });
          return ({
            name: "section-screen",
            params: { section: 'kids', lang: currentLanguage },
          });
      } else {
        
        // this.$router.push({name: "Home", params: {lang: currentLanguage }});
        return ({name: "Home", params: {lang: currentLanguage }});
      }
    },
    actGetDataCapitalize (words) {

      const sentance = words.split(" ");
      for (let i = 0; i < sentance.length; i++) {
        sentance[i] = sentance[i][0].toUpperCase() + sentance[i].substr(1);
      }
      return sentance.join(" ");

    },
    categoryShow(category) {
      if(category == 'Movie') {
        return 'Movies';
      }
      return category;
    }
  },
  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./BreadCrumbs.scss";
</style>
